import { Fragment, useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from 'uuid';
import { containsSpecialChars } from "../../../CommonComponents/CommonFunctions";
import GroundStationService from "../../../../service/GroundStationService";
import MissionService from "../../../../service/MissionService";
import CryptoJS from 'crypto-js';
interface GsProviderConfigurationModalProps {
    close: () => void
    openModal: any;
    configType?: string;
    all_providers: any[];
    all_ground_stations: any[];
}


const GsProviderConfigurationModal: React.FC<GsProviderConfigurationModalProps> = ({ close, openModal, all_providers, all_ground_stations, configType }) => {

    const params = useParams();
    const { mission_id } = params
    const [defaultProviderList, setDefaultProviderList] = useState<any>([
        {
            value: 'ATLAS',
            label: 'ATLAS',
            isDisabled: false,
        },
        {
            value: 'Leaf Space',
            label: 'Leaf Space',
            isDisabled: false,
        },
        {
            label: 'Custom',
            value: 'Custom',
            isDisabled: false,
        }
    ])
    const customStyles = {
        option: (base, state) => ({
            ...base,
            opacity: state.isDisabled ? '50%' : '100%',
            cursor: state.isDisabled ? 'default' : 'pointer',
            backgroundColor: state.isDisabled ? 'inherit' : base.backgroundColor,
        }),
    };

    const [validationErr, setValidationErr] = useState<boolean>(false)
    const [showPassword, setShowPassword] = useState<boolean | string>(false)
    const [selectedProvider, setSelectedProvider] = useState<any>(null)
    const [providerDetails, setProviderDetails] = useState<any>({})
    const [loading, setLoading] = useState<any>(false)

    const scrollRef = useRef(null)
    const [testConfig, setTestConfig] = useState<any>(false)

    const check_validation = () => {
        if (!providerDetails?.['providerName'] || providerDetails?.['providerName'] === '' || (providerDetails?.['providerName'] && providerDetails?.['providerName'].toLowerCase().includes('untitled'))) {
            toast.error(providerDetails?.['providerName'].toLowerCase().includes('untitled') ? `Provider name can't contain the word "untitled".` : 'Provider name is required.', { toastId: 'o' })
            return true
        } else if (!providerDetails?.['APIEndpoint'] || providerDetails?.['APIEndpoint'] === '') {
            toast.error('API Endpoint is required.', { toastId: 'o' })
            return true
        } else if (!providerDetails?.['APIUsername'] || providerDetails?.['APIUsername'] === '') {
            toast.error('Username is required for API Endpoint.', { toastId: 'o' })
            return true
        } else if (!providerDetails?.['APIPassword'] || providerDetails?.['APIPassword'] === '') {
            toast.error('Password is required for API Endpoint.', { toastId: 'o' })
            return true
        } else if (!providerDetails?.['MQTTEndpoint'] || providerDetails?.['MQTTEndpoint'] === '') {
            toast.error('MQTT Endpoint is required.', { toastId: 'o' })
            return true
        } else if (!providerDetails?.['MQTTUsername'] || providerDetails?.['MQTTUsername'] === '') {
            toast.error('Username is required for MQTT Endpoint.', { toastId: 'o' })
            return true
        } else if (!providerDetails?.['MQTTPassword'] || providerDetails?.['MQTTPassword'] === '') {
            toast.error('Password is required for MQTT Endpoint.', { toastId: 'o' })
            return true
        } else if (testConfig && (!providerDetails?.['TestAPIEndpoint'] || providerDetails?.['TestAPIEndpoint'] === '')) {
            toast.error('Test API Endpoint is required.', { toastId: 'o' })
            return true
        } else if (testConfig && (!providerDetails?.['TestAPIUsername'] || providerDetails?.['TestAPIUsername'] === '')) {
            toast.error('Username is required for Test API Endpoint.', { toastId: 'o' })
            return true
        } else if (testConfig && (!providerDetails?.['TestAPIPassword'] || providerDetails?.['TestAPIPassword'] === '')) {
            toast.error('Password is required for Test API Endpoint.', { toastId: 'o' })
            return true
        } else if (testConfig && (!providerDetails?.['TestMQTTEndpoint'] || providerDetails?.['TestMQTTEndpoint'] === '')) {
            toast.error('Test MQTT Endpoint is required.', { toastId: 'o' })
            return true
        } else if (testConfig && (!providerDetails?.['TestMQTTUsername'] || providerDetails?.['TestMQTTUsername'] === '')) {
            toast.error('Username is required for Test MQTT Endpoint.', { toastId: 'o' })
            return true
        } else if (testConfig && (!providerDetails?.['TestMQTTPassword'] || providerDetails?.['TestMQTTPassword'] === '')) {
            toast.error('Password is required for Test MQTT Endpoint.', { toastId: 'o' })
            return true
        } else {
            return false
        }
    }

    const encrypt = (password) => {
        let secret = process.env.REACT_APP_SECRET_KEY!
        var encrypted = CryptoJS.AES.encrypt(password, secret).toString();
        return encrypted;
    }

    const decrypt = (encryptedData) => {
        let secret = process.env.REACT_APP_SECRET_KEY!
        var bytes = CryptoJS.AES.decrypt(encryptedData, secret);
        var decrypted = bytes.toString(CryptoJS.enc.Utf8);
        return decrypted;
    };



    useEffect(() => {
        if (all_providers?.length > 0) {
            all_providers?.map((provider: any) => {
                if (!provider?.['customProvider']) {
                    const providerIndex = defaultProviderList?.findIndex((item) => item.label === provider?.['providerName']);

                    if (providerIndex !== -1) { // Ensure provider is found
                        defaultProviderList[providerIndex] = {
                            label: provider?.['providerName'],
                            value: provider?.['providerName'],
                            isDisabled: true,
                        };
                    }
                }
            })
        }
    }, [all_providers])


    useEffect(() => {
        if (openModal?.['mode'] === 'Update') {
            let hash_passwords: any = {}
            if (openModal?.['data']?.['APIEndpoint']) {
                if (openModal?.['data']?.['TestAPIEndpoint']) {
                    hash_passwords = {
                        APIPassword: openModal?.['data']?.['APIPassword'],
                        MQTTPassword: openModal?.['data']?.['MQTTPassword'],
                        TestAPIPassword: openModal?.['data']?.['APIPassword'],
                        TestMQTTPassword: openModal?.['data']?.['MQTTPassword'],
                    }
                } else {
                    hash_passwords = {
                        APIPassword: openModal?.['data']?.['APIPassword'],
                        MQTTPassword: openModal?.['data']?.['MQTTPassword']
                    }
                }
            }
            setProviderDetails(Object.keys(hash_passwords)?.length > 0 ? {
                ...openModal?.['data'],
                ...hash_passwords
            } : {
                ...openModal?.['data'],
            })
            setSelectedProvider(openModal?.['data']?.['customProvider'] ? 'Custom' : openModal?.['data']?.['providerName'])
            if (configType === 'add_test_configuration') {
                setTestConfig(true)
            } else {
                setTestConfig(openModal?.['data']?.['TestAPIEndpoint'] ? true : false)
            }
        }
    }, [openModal])

    const create_new_provider = (provider) => {
        const updated_provider = {
            ...provider,
            'providerName': provider?.['providerName']?.trimEnd()
        }
        GroundStationService.createGsProvider(updated_provider)?.then((res) => {
            if (res) {
                setLoading(false)
                toast.success(`GS Provider created successfully`, { toastId: 'o' })
                close()
            }
        }).catch((err) => {
            setLoading(false)
            console.error(err);
        })
    }

    const update_mission_ground_stations = (providers) => {
        let updated_mission_ground_stations: any = []
        providers?.map((provider: any) => {
            updated_mission_ground_stations = updated_mission_ground_stations?.length > 0 ? [...updated_mission_ground_stations, ...provider?.groundStations] : [...provider?.groundStations]
        })

        MissionService.updateMissionStations(mission_id, updated_mission_ground_stations)?.then((res) => {
            if (res?.data) {
                toast.success(`GS Provider updated successfully`, { toastId: 'o' })
            }
        }).catch((err) => {
            console.error(err);
            toast.error('Failed to update station.')
        })

    }

    const update_existing_provider = (provider) => {
        const updated_provider = {
            ...provider,
            'providerName': provider?.['providerName']?.trimEnd()
        }
        let filter_providers = all_providers?.filter(item => item?.['uniqueId'] !== provider?.['uniqueId'])
        let providers_list = [...filter_providers, updated_provider]
        GroundStationService.updateGsProvider(provider?.['uniqueId'], updated_provider)?.then((res) => {
            if (res) {
                update_ground_station(updated_provider)
                update_mission_ground_stations(providers_list)
            }
        }).catch((err) => {
            setLoading(false)
            console.error(err);
        })
    }

    const update_ground_station = (provider) => {
        let provider_associated_gs = all_ground_stations?.filter(station => provider?.['groundStations'] && provider?.['groundStations']?.includes(station?.['groundStationId']))
        let updated_provider_associated_gs: any = []
        provider_associated_gs?.map((station: any) => {
            if (station?.['owner-org'] !== "System") {
                let data = {
                    ...station,
                    properties: {
                        ...station?.['properties'],
                        provider: provider?.['providerName'],
                    },
                    status: station?.['status']
                }
                updated_provider_associated_gs = [...updated_provider_associated_gs, data]
            }
        })
        if (updated_provider_associated_gs?.length > 0) {
            GroundStationService.updateGroundStations(updated_provider_associated_gs)?.then((res) => {
                if (res?.data) {
                    setLoading(false)
                    close()
                }
            })?.catch((err) => {
                console.error(err);
                toast.error('Something went wrong while updating ground stations.')
                setLoading(false)
            })
        } else {
            setLoading(false)
            close()
        }
    }

    const handle_submit_form = async () => {
        let validation = check_validation()
        if (validation) {
            setValidationErr(true)
        } else {
            setLoading(true)
            setValidationErr(false)
            let not_configured_provider_stations = all_ground_stations?.filter(station => providerDetails?.['groundStations'] && providerDetails?.['groundStations']?.includes(station?.['groundStationId']) && station?.['status'] === 'Configure')

            let hash_passwords: any = {}
            if (!testConfig) {
                Object.keys(providerDetails).map(async (key: any) => {
                    if (key.includes('Test')) {
                        delete providerDetails[key]
                    }
                })
                hash_passwords = {
                    APIPassword: providerDetails?.['APIPassword'],
                    MQTTPassword: providerDetails?.['MQTTPassword']
                }
            } else {
                hash_passwords = {
                    APIPassword: providerDetails?.['APIPassword'],
                    MQTTPassword: providerDetails?.['MQTTPassword'],
                    TestAPIPassword: providerDetails?.['APIPassword'],
                    TestMQTTPassword: providerDetails?.['MQTTPassword'],
                }
            }

            let data = {
                ...providerDetails,
                ...hash_passwords,
                missionId: mission_id,
                mqttConfiguredBands: providerDetails?.['mqttConfiguredBands'] ? providerDetails?.['mqttConfiguredBands'] : [],
                uniqueId: openModal?.['mode'] === 'Update' ? providerDetails?.['uniqueId'] : uuidv4(),
                nextConfigStep: (!providerDetails?.['MQTTEndpoint'] || providerDetails?.['MQTTEndpoint'] === '') ? 'Configure Provider' : (!providerDetails?.['groundStations'] || providerDetails?.['groundStations']?.length === 0) ? 'Add Ground Station' : not_configured_provider_stations?.length > 0 ? 'Configure Ground Station' : (!providerDetails?.['MQTTTopicList'] || providerDetails?.['MQTTTopicList']?.length === 0) ? 'Configure MQTT Topics' : '',
                status: ((!providerDetails?.['MQTTEndpoint'] || providerDetails?.['MQTTEndpoint'] === '') || (!providerDetails?.['groundStations'] || providerDetails?.['groundStations']?.length === 0) || (!providerDetails?.['MQTTTopicList'] || providerDetails?.['MQTTTopicList']?.length === 0)) ? 'Not Configured' : 'Configured',
            }

            if (openModal?.['mode'] === 'Update') {
                update_existing_provider(data)
            } else {
                create_new_provider(data)
            }
        }
    }

    const scroll_to_bottom = () => {
        const container = scrollRef.current;
        if (container) {
            (container as HTMLElement).scrollTop = (container as HTMLElement).scrollHeight;
            (container as HTMLElement).scrollIntoView({ behavior: 'smooth' });
        }
    }

    const provider_exist = () => {
        let name_exist = false
        if (openModal?.['mode'] === 'Create') {
            let filter_providers = all_providers.filter((provider: any) => provider?.['uniqueId'] !== providerDetails?.['uniqueId'])

            filter_providers.map((provider: any) => {
                if (provider?.['providerName'] === providerDetails?.['providerName']) {
                    name_exist = true
                } else if (providerDetails?.['customProvider'] === true && (providerDetails?.['providerName'].toLowerCase().replace(/\s/g, "") === 'atlas' || providerDetails?.['providerName'].toLowerCase().replace(/\s/g, "") === 'leafspace')) {
                    name_exist = true
                }
            })
        } else if (openModal?.['mode'] === 'Update') {
            all_providers.map((provider: any) => {
                if (providerDetails?.['customProvider'] === true && (providerDetails?.['providerName'].toLowerCase().replace(/\s/g, "") === 'atlas' || providerDetails?.['providerName'].toLowerCase().replace(/\s/g, "") === 'leafspace')) {
                    name_exist = true
                }
            })
        }

        return name_exist
    }

    useEffect(() => {
        const container = scrollRef.current;
        if (!providerDetails?.['TestAPIEndpoint'] && testConfig && container) {
            (container as HTMLElement).scrollTop = (container as HTMLElement).scrollHeight;
            (container as HTMLElement).scrollIntoView({ behavior: 'smooth' });
        }
    }, [openModal, testConfig, scrollRef])

    const toggle_test_config = () => {
        if (!testConfig) {
            if (Object.keys(providerDetails).length === 0) {
                setTestConfig(false)
                setValidationErr(true)
            } else {
                Object.keys(providerDetails).map((key: any) => {
                    if (!key.includes('Test') && providerDetails[key] === '') {
                        setTestConfig(false)
                        setValidationErr(true)
                    } else {
                        setTestConfig(true)
                        setValidationErr(false)
                    }
                })
            }
        } else {
            setTestConfig(false)
        }
    }


    return (
        <div className='popup-model z-2'>
            <div className='model-md gap-3 p-0'>
                <div className='w-100 d-flex justify-content-between align-items-center px-5 pt-5'>
                    <span className='form-heade-text fs-18' > Ground Station Provider</span>
                    <i onClick={close} className="fe fe-x-circle me-1 fs-25 cursor-pointer text-white"></i>
                </div>

                <div className="modal_devider" />

                <Form validated={validationErr} className={`d-flex flex-column gap-3 transition-height pop-scroll-md px-5 pb-5`} ref={scrollRef}>
                    <div className={`w-100 d-flex gap-4 align-items-center ${openModal?.['mode'] === 'Update' ? 'opacity-50' : ''}`}>
                        <span>Ground Station Provider *</span>
                        <div className="d-flex w-70 flex-column gap-2">
                            <Select
                                isDisabled={openModal?.['mode'] === 'Update'}
                                className="w-100"
                                required
                                classNamePrefix="Select"
                                options={defaultProviderList}
                                styles={customStyles}
                                placeholder="Select provider"
                                value={selectedProvider ? { label: selectedProvider, value: providerDetails } : null}
                                onKeyDown={(e) => {
                                    if (e.keyCode === 13) {
                                        e.preventDefault();
                                    }
                                }}
                                onChange={(e: any) => {
                                    setSelectedProvider(e.value)
                                    setProviderDetails({
                                        ...providerDetails,
                                        providerName: e.value !== 'Custom' ? e.value : '',
                                        customProvider: e.value === 'Custom' ? true : false
                                    })
                                }}
                            />
                            <Form.Control.Feedback type="invalid">Provider is required.</Form.Control.Feedback>
                        </div>
                    </div>

                    <div className="modal_devider" />

                    <Fragment>
                        <Fragment>
                            {providerDetails?.['customProvider'] && (
                                <div className="w-100 d-flex flex-column gap-2 w-100">
                                    <span>Custom GS Provider Name *</span>
                                    <Form.Control className="w-100" placeholder="Enter provider name"
                                        required
                                        value={providerDetails?.['providerName']}
                                        onChange={(e) => {
                                            if (!containsSpecialChars(e.target.value) && e.target.value?.length <= 30) {
                                                setLoading(false)
                                                setProviderDetails({
                                                    ...providerDetails,
                                                    providerName: e.target.value.trimStart()
                                                })
                                            }
                                        }}
                                    />
                                    <Form.Control.Feedback type="invalid">Provider name is required.</Form.Control.Feedback>
                                </div>
                            )}
                            <div className="w-100 d-flex flex-column gap-2">
                                <span>API Endpoint *</span>
                                <Form.Control className="w-100" placeholder={`Enter API Endpoint`}
                                    required
                                    value={providerDetails?.['APIEndpoint'] ? providerDetails?.['APIEndpoint'] : ''}
                                    disabled={!selectedProvider}
                                    onChange={(e) => {
                                        setProviderDetails({
                                            ...providerDetails,
                                            APIEndpoint: e.target.value.trim()
                                        })
                                    }}
                                />
                                <Form.Control.Feedback type="invalid">API Endpoint is required</Form.Control.Feedback>
                            </div>

                            <div className="d-flex w-100 gap-3 align-items-center">
                                <div className="w-100 d-flex flex-column gap-2">
                                    <span>Username *</span>
                                    <Form.Control className="w-100" placeholder={`Enter username`}
                                        required
                                        value={providerDetails?.['APIUsername'] ? providerDetails?.['APIUsername'] : ''}
                                        disabled={!selectedProvider}
                                        onChange={(e) => {
                                            setProviderDetails({
                                                ...providerDetails,
                                                APIUsername: e.target.value.trim()
                                            })
                                        }}
                                    />
                                    <Form.Control.Feedback type="invalid">Username is required</Form.Control.Feedback>
                                </div>
                                <div className="w-100 d-flex flex-column gap-2">
                                    <span>Password *</span>
                                    <div className="w-100 d-flex flex-column gap-2 position-relative justify-content-center">
                                        {providerDetails?.['APIPassword'] && providerDetails?.['APIPassword'] !== '' &&
                                            <button
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    setShowPassword(showPassword === 'APIPassword' ? false : 'APIPassword')
                                                }}
                                                className="btn-close bg-light-black right-0 d-flex align-items-center p-1 px-2 justify-content-center position-absolute">
                                                <i className={`fe ${showPassword === 'APIPassword' ? 'fe-eye-off' : 'fe-eye'} text-white fs-18`} />
                                            </button>
                                        }
                                        <Form.Control className="w-100" placeholder={`Enter password`}
                                            type={showPassword === 'APIPassword' ? 'text' : 'password'}
                                            required
                                            disabled={!selectedProvider}
                                            minLength={1}
                                            value={providerDetails?.['APIPassword'] ? providerDetails?.['APIPassword'] : ''}
                                            onChange={(e) => {
                                                setProviderDetails({
                                                    ...providerDetails,
                                                    APIPassword: e.target.value.trim()
                                                })
                                            }}
                                        />
                                        <Form.Control.Feedback type="invalid">Password field can not be empty.</Form.Control.Feedback>
                                    </div>
                                </div>
                            </div>
                        </Fragment>

                        <Fragment>
                            <div className="w-100 d-flex flex-column gap-2">
                                <span>MQTT Endpoint *</span>
                                <Form.Control className="w-100" placeholder={`Enter MQTT Endpoint`}
                                    required
                                    value={providerDetails?.['MQTTEndpoint'] ? providerDetails?.['MQTTEndpoint'] : ''}
                                    disabled={!selectedProvider}
                                    onChange={(e) => {
                                        setProviderDetails({
                                            ...providerDetails,
                                            MQTTEndpoint: e.target.value.trim()
                                        })
                                    }}
                                />
                                <Form.Control.Feedback type="invalid">MQTT Endpoint is required</Form.Control.Feedback>
                            </div>

                            <div className="d-flex w-100 gap-3 align-items-center">
                                <div className="w-100 d-flex flex-column gap-2">
                                    <span>Username *</span>
                                    <Form.Control className="w-100" placeholder={`Enter username`}
                                        required
                                        disabled={!selectedProvider}
                                        value={
                                            providerDetails?.['MQTTUsername'] ? providerDetails?.['MQTTUsername'] : ''
                                        }
                                        onChange={(e) => {
                                            setProviderDetails({
                                                ...providerDetails,
                                                MQTTUsername: e.target.value.trim()
                                            })
                                        }}
                                    />
                                    <Form.Control.Feedback type="invalid">Username is required</Form.Control.Feedback>
                                </div>
                                <div className="w-100 d-flex flex-column gap-2">
                                    <span>Password *</span>
                                    <div className="w-100 d-flex flex-column gap-2 position-relative justify-content-center">
                                        {providerDetails?.['MQTTPassword'] && providerDetails?.['MQTTPassword'] !== '' &&
                                            <button
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    setShowPassword(showPassword === 'MQTTPassword' ? false : 'MQTTPassword')
                                                }}
                                                className="btn-close bg-light-black right-0 d-flex align-items-center p-1 px-2 justify-content-center position-absolute">
                                                <i className={`fe ${showPassword === 'MQTTPassword' ? 'fe-eye-off' : 'fe-eye'} text-white fs-18`} />
                                            </button>
                                        }
                                        <Form.Control className="w-100" placeholder={`Enter password`}
                                            type={showPassword === 'MQTTPassword' ? 'text' : 'password'}
                                            required
                                            disabled={!selectedProvider}
                                            minLength={1}
                                            value={providerDetails?.['MQTTPassword'] ? providerDetails?.['MQTTPassword'] : ''}
                                            onChange={(e) => {
                                                setProviderDetails({
                                                    ...providerDetails,
                                                    MQTTPassword: e.target.value.trim()
                                                })
                                            }}
                                        />
                                        <Form.Control.Feedback type="invalid">Password field can not be empty.</Form.Control.Feedback>
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                    </Fragment>

                    <div className="d-flex w-100 justify-content-end">
                        <button
                            className={`bg-transparent border-0 ${testConfig ? 'color-atmos' : 'color_history'}`}
                            disabled={!selectedProvider}
                            onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                toggle_test_config()
                                scroll_to_bottom()
                            }}>
                            Add Test Configuration
                        </button>
                    </div>

                    {testConfig &&
                        <Fragment>
                            <Fragment>
                                <div className="w-100 d-flex flex-column gap-2">
                                    <span>Test API Endpoint *</span>
                                    <Form.Control className="w-100" placeholder={`Enter Test API Endpoint`}
                                        required
                                        value={providerDetails?.['TestAPIEndpoint'] ? providerDetails?.['TestAPIEndpoint'] : ''}
                                        onChange={(e) => {
                                            setProviderDetails({
                                                ...providerDetails,
                                                TestAPIEndpoint: e.target.value.trim()
                                            })
                                        }}
                                    />
                                    <Form.Control.Feedback type="invalid">Test API Endpoint is required</Form.Control.Feedback>
                                </div>

                                <div className="d-flex w-100 gap-3 align-items-center">
                                    <div className="w-100 d-flex flex-column gap-2">
                                        <span>Username *</span>
                                        <Form.Control className="w-100" placeholder={`Enter username`}
                                            required
                                            value={providerDetails?.['TestAPIUsername'] ? providerDetails?.['TestAPIUsername'] : ''}
                                            onChange={(e) => {
                                                setProviderDetails({
                                                    ...providerDetails,
                                                    TestAPIUsername: e.target.value.trim()
                                                })
                                            }}
                                        />
                                        <Form.Control.Feedback type="invalid">Username is required</Form.Control.Feedback>
                                    </div>
                                    <div className="w-100 d-flex flex-column gap-2">
                                        <span>Password *</span>
                                        <div className="w-100 d-flex flex-column gap-2 position-relative justify-content-center">
                                            {providerDetails?.['TestAPIPassword'] && providerDetails?.['TestAPIPassword'] !== '' &&
                                                <button
                                                    onClick={(e) => {
                                                        e.preventDefault()
                                                        setShowPassword(showPassword === 'TestAPIPassword' ? false : 'TestAPIPassword')
                                                    }}
                                                    className="btn-close bg-light-black right-0 d-flex align-items-center p-1 px-2 justify-content-center position-absolute">
                                                    <i className={`fe ${showPassword === 'TestAPIPassword' ? 'fe-eye-off' : 'fe-eye'} text-white fs-18`} />
                                                </button>
                                            }
                                            <Form.Control className="w-100" placeholder={`Enter password`}
                                                type={showPassword === 'TestAPIPassword' ? 'text' : 'password'}
                                                required
                                                minLength={1}
                                                value={providerDetails?.['TestAPIPassword'] ? providerDetails?.['TestAPIPassword'] : ''}
                                                onChange={(e) => {
                                                    setProviderDetails({
                                                        ...providerDetails,
                                                        TestAPIPassword: e.target.value.trim()
                                                    })
                                                }}
                                            />
                                            <Form.Control.Feedback type="invalid">Password field can not be empty.</Form.Control.Feedback>
                                        </div>
                                    </div>
                                </div>
                            </Fragment>

                            <Fragment>
                                <div className="w-100 d-flex flex-column gap-2">
                                    <span>Test MQTT Endpoint *</span>
                                    <Form.Control className="w-100" placeholder={`Enter Test MQTT Endpoint`}
                                        required
                                        value={providerDetails?.['TestMQTTEndpoint'] ? providerDetails?.['TestMQTTEndpoint'] : ''}
                                        onChange={(e) => {
                                            setProviderDetails({
                                                ...providerDetails,
                                                TestMQTTEndpoint: e.target.value.trim()
                                            })
                                        }}
                                    />
                                    <Form.Control.Feedback type="invalid">Test MQTT Endpoint is required</Form.Control.Feedback>
                                </div>

                                <div className="d-flex w-100 gap-3 align-items-center">
                                    <div className="w-100 d-flex flex-column gap-2">
                                        <span>Username *</span>
                                        <Form.Control className="w-100" placeholder={`Enter username`}
                                            required
                                            value={
                                                providerDetails?.['TestMQTTUsername'] ? providerDetails?.['TestMQTTUsername'] : ''
                                            }
                                            onChange={(e) => {
                                                setProviderDetails({
                                                    ...providerDetails,
                                                    TestMQTTUsername: e.target.value.trim()
                                                })
                                            }}
                                        />
                                        <Form.Control.Feedback type="invalid">Username is required</Form.Control.Feedback>
                                    </div>
                                    <div className="w-100 d-flex flex-column gap-2">
                                        <span>Password *</span>
                                        <div className="w-100 d-flex flex-column gap-2 position-relative justify-content-center">
                                            {providerDetails?.['TestMQTTPassword'] && providerDetails?.['TestMQTTPassword'] !== '' &&
                                                <button
                                                    onClick={(e: any) => {
                                                        e.preventDefault()
                                                        setShowPassword(showPassword === 'TestMQTTPassword' ? false : 'TestMQTTPassword')
                                                    }}
                                                    className="btn-close bg-light-black right-0 d-flex align-items-center p-1 px-2 justify-content-center position-absolute">
                                                    <i className={`fe ${showPassword === 'TestMQTTPassword' ? 'fe-eye-off' : 'fe-eye'} text-white fs-18`} />
                                                </button>
                                            }
                                            <Form.Control className="w-100" placeholder={`Enter password`}
                                                type={showPassword === 'TestMQTTPassword' ? 'text' : 'password'}
                                                required
                                                minLength={1}
                                                value={providerDetails?.['TestMQTTPassword'] ? providerDetails?.['TestMQTTPassword'] : ''}
                                                onChange={(e: any) => {
                                                    setProviderDetails({
                                                        ...providerDetails,
                                                        TestMQTTPassword: e.target.value.trim()
                                                    })
                                                }}
                                            />
                                            <Form.Control.Feedback type="invalid">Password field can not be empty.</Form.Control.Feedback>
                                        </div>
                                    </div>
                                </div>
                            </Fragment>
                        </Fragment>
                    }
                </Form>
                <div className="modal_devider" />
                <div className="w-100 d-flex justify-content-between align-items-center mt-3 px-5 pb-5">
                    <button disabled={loading} onClick={close} className="btn color-text" style={{
                        border: '0.5px solid #F0F0F0'
                    }}>Close</button>
                    <button disabled={!selectedProvider || loading || Object.keys(providerDetails)?.length === 0} onClick={() => {
                        let name_exist = false
                        if (all_providers?.length > 0) {
                            name_exist = provider_exist()
                        } else if (providerDetails?.['customProvider'] === true && (providerDetails?.['providerName'].toLowerCase().replace(/\s/g, "") === 'atlas' || providerDetails?.['providerName'].toLowerCase().replace(/\s/g, "") === 'leafspace') || providerDetails?.['providerName'].toLowerCase().includes('untitled')) {
                            name_exist = true
                        }
                        if (name_exist) {
                            toast.error(providerDetails?.['providerName'].toLowerCase().includes('untitled') ? 'Provider name cannot be "Untitled".' : 'Provider name already exists.', { toastId: 'o' })
                        } else {
                            handle_submit_form()
                        }
                    }} className="btn btn-primary">{openModal?.['mode'] === 'Create' ? 'Save' : 'Update'}</button>
                </div>
            </div>

        </div>
    )
}

export default GsProviderConfigurationModal