// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { Button, Dropdown, OverlayTrigger, Popover } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { setEdgePowerUsage } from '../../../../../store/UsageSlice';
import { Steps } from '../../Stepper.constants';
import { useNavigate, useParams } from 'react-router-dom';
import PageHeader from '../../../../../Layouts/PageHeader/PageHeader';
import { calculateEdgeUsage, calculate_Total_AOP_Usage, navigateTo } from '../../../../CommonComponents/CommonFunctions';
import CloseButton from '../../../../CommonComponents/CloseButton';
import { toast } from 'react-toastify';
import MissionService from '../../../../../service/MissionService';
import Dialog from '../../../../CommonComponents/Dialog';
import Loader from '../../../../../Layouts/Loader/Loader';

const EdgePreview = () => {
    const params = useParams()
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { mission_name, sat_name, mission_id, sat_id } = params
    const [hover, setHover] = useState(true)
    const [loading, setLoading] = useState(true)
    const [isEdgeDelete, setIsEdgeDelete] = useState(false)
    const [satEdges, setSatEdges] = useState<any[]>([]);
    const [satellieteDeta, setSatellieteData] = useState<any>({});

    const [deletedEdgeData, setDeletedEdgeData] = useState<any>({});
    const [openDeleteModel, setOpenDeleteModal] = useState<any>(false);


    const get_satellites = () => {
        MissionService.get_satellite_by_sat_id(sat_id)?.then((sat) => {
            if (sat?.data) {
                let all_edges = sat.data?.["versions"]?.[0]?.["assembly"]?.["Edge"] ? sat.data?.["versions"]?.[0]?.["assembly"]?.["Edge"] : []
                let all_payloads = sat.data?.["versions"]?.[0]?.["assembly"]?.["Payloads"] ? sat.data?.["versions"]?.[0]?.["assembly"]?.["Payloads"] : []
                setSatEdges(all_edges)
                setSatellieteData(sat.data)
                calculateEdgeUsage(all_edges, dispatch)
                calculate_Total_AOP_Usage(all_payloads, all_edges, dispatch)
            }
            setLoading(false)
        }).catch((err) => {
            console.log('Some thing went wrong while getting satellite');
            setLoading(false)
        })
    }
    useEffect(() => {
        if (sat_id) {
            get_satellites()
        }
    }, [sat_id]);

    const update_satellite = (select_edges: any) => {
        let sat = {
            ...satellieteDeta,
            name: satellieteDeta?.['name'],
            draftMode: 'Yes',
            "containing-mission": satellieteDeta?.['containing-mission'],
            versions: [
                {
                    assembly: {
                        'Payloads': satellieteDeta?.['versions']?.[0]?.['assembly']?.['Payloads'],
                        'Edge': select_edges,
                        'Template': '',
                    },
                },
            ],
            "swap-info": satellieteDeta?.["swap-info"],
        };
        MissionService.update_satellites(sat_id, sat)?.then((res) => {
            if (res.data) {
                toast.success('Edge deleted successfully', { toastId: 'o' });
                get_satellites()
            }
        })
            .catch((err) => {
                toast.error("Something went wrong while adding payload ", { toastId: 'o' });
                setLoading(false)
            });
    }

    const deleteEdge = (data) => {
        setLoading(true)
        let all_payloads = satellieteDeta?.["versions"]?.[0]?.["assembly"]?.["Payloads"] ? satellieteDeta?.["versions"]?.[0]?.["assembly"]?.["Payloads"] : []
        if (satEdges?.length === 0) {
            update_satellite([])
            setSatEdges([])
            dispatch(setEdgePowerUsage(0));
            calculate_Total_AOP_Usage(all_payloads, [], dispatch)
        } else {
            const filtered_Edges = satEdges?.filter((item) => item?.['edge-id'] != data?.['edge-id'])
            update_satellite(filtered_Edges)
            setSatEdges(filtered_Edges)
            calculateEdgeUsage(filtered_Edges, dispatch)
            calculate_Total_AOP_Usage(all_payloads, filtered_Edges, dispatch)
            setIsEdgeDelete(true)
        }
    }

    const navigate_to_step = (step) => {
        if (step === 'Payloads') {
            navigateTo(`${mission_name}/${mission_id}/${sat_name}/${sat_id}/preview_payload`, navigate)
        }
        if (step === 'Bus Design') {
            navigateTo(`${mission_name}/${mission_id}/${sat_name}/${sat_id}/add_new_template`, navigate)
        }
    }

    return <div className='h-100 w-100 d-flex flex-column overflow-hidden'>
        {loading && <Loader />}
        <PageHeader sat_name={sat_name} titles="Edge Devices" description="Edge device is a compute node used to perform specialized data processing like encryption, compression, etc." active="New Mission" items={['Apps']}
            isButton={false}
            showSwap={true}
            showPercent={false}
            type=''
            value=''
        />
        {satEdges?.length === 2 && <div className='badge__info '>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M10 2.5C5.85938 2.5 2.5 5.85938 2.5 10C2.5 14.1406 5.85938 17.5 10 17.5C14.1406 17.5 17.5 14.1406 17.5 10C17.5 5.85938 14.1406 2.5 10 2.5ZM9.76562 14.375C9.61111 14.375 9.46006 14.3292 9.33159 14.2433C9.20311 14.1575 9.10298 14.0355 9.04384 13.8927C8.98471 13.75 8.96924 13.5929 8.99939 13.4413C9.02953 13.2898 9.10394 13.1506 9.2132 13.0413C9.32246 12.9321 9.46166 12.8577 9.61321 12.8275C9.76476 12.7974 9.92184 12.8128 10.0646 12.872C10.2074 12.9311 10.3294 13.0312 10.4152 13.1597C10.5011 13.2882 10.5469 13.4392 10.5469 13.5938C10.5469 13.801 10.4646 13.9997 10.3181 14.1462C10.1715 14.2927 9.97283 14.375 9.76562 14.375ZM11.0719 10.3906C10.4387 10.8156 10.3516 11.2051 10.3516 11.5625C10.3516 11.7075 10.2939 11.8466 10.1914 11.9492C10.0888 12.0518 9.94973 12.1094 9.80469 12.1094C9.65965 12.1094 9.52055 12.0518 9.41799 11.9492C9.31543 11.8466 9.25781 11.7075 9.25781 11.5625C9.25781 10.7066 9.65156 10.0262 10.4617 9.48203C11.2148 8.97656 11.6406 8.65625 11.6406 7.95195C11.6406 7.47305 11.3672 7.10938 10.8012 6.84023C10.668 6.77695 10.3715 6.71523 10.0066 6.71953C9.54883 6.72539 9.19336 6.83477 8.91953 7.05508C8.40312 7.4707 8.35938 7.92305 8.35938 7.92969C8.35591 8.0015 8.33834 8.07194 8.30766 8.13696C8.27697 8.20199 8.23379 8.26033 8.18055 8.30866C8.12732 8.357 8.06509 8.39437 7.99742 8.41866C7.92974 8.44294 7.85795 8.45366 7.78613 8.4502C7.71432 8.44673 7.64388 8.42916 7.57886 8.39848C7.51383 8.36779 7.45549 8.3246 7.40716 8.27137C7.35882 8.21814 7.32145 8.15592 7.29716 8.08824C7.27288 8.02057 7.26216 7.94877 7.26562 7.87695C7.26992 7.78203 7.33594 6.92695 8.2332 6.20508C8.69844 5.83086 9.29023 5.63633 9.99102 5.62773C10.4871 5.62188 10.9531 5.70586 11.2691 5.85508C12.2148 6.30234 12.7344 7.04805 12.7344 7.95195C12.7344 9.27344 11.8512 9.8668 11.0719 10.3906Z" fill="#EEEEEE" />
            </svg>
            <span>Maximum Edge device capacity reached. You can only add 2 Edge devices to your satellite.</span>
        </div>}
        <div className="d-flex flex-wrap flex-1 overflow-auto py-4 gap-4 ps-7 align-content-start">
            {satEdges?.length > 0 && satEdges?.map((data, i) => {
                return <div className="common-card-box-preview-edge m-0" key={i} style={{ width: '440px' }}>
                    <div className="common-card-header d-flex justify-content-between align-items-start mb-1 pb-6">
                        <span className="fs-18 color-text w-90 text-truncate" title={data?.['edge-device']?.['atmos-data']?.['name']}>
                            {data?.['edge-device']?.['atmos-data']?.['name']}
                        </span>
                        <div className="btn-list more-button">
                            <Dropdown drop='end'>
                                <Dropdown.Toggle variant="transparent text-white fs-20">
                                    <i className="fe fe-more-vertical"></i>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item eventKey="1"
                                        onClick={() => {
                                            navigateTo(`${mission_name}/${mission_id}/${sat_name}/${sat_id}/edit_edge/${data?.['edge-device']?.['atmos-data']?.['name']}/${data?.['edge-id']}`, navigate)
                                        }}>
                                        Edit
                                    </Dropdown.Item>
                                    <Dropdown.Item eventKey="2"
                                        onClick={() => {
                                            setDeletedEdgeData(data)
                                            setOpenDeleteModal(true)
                                        }}
                                    >Delete</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                    <div className="common-card-content mt-1">
                        <div className="d-flex flex-wrap">
                            <div className="stats-alpha">
                                <strong>{data?.['edge-device']?.['atmos-data']?.['product-cookie']?.['CPU Cores'] + ' Cores'}</strong>
                                <span>CPU Cores</span>
                            </div>
                            <div className="stats-alpha">
                                <strong>{data?.['edge-device']?.['atmos-data']?.['product-cookie']?.['GPU']}</strong>
                                <span>GPU</span>
                            </div>
                            <div className="stats-alpha">
                                <strong>{data?.['edge-device']?.['atmos-data']?.['product-cookie']?.['Storage']}</strong>
                                <span>Storage</span>
                            </div>
                            <div className="stats-alpha">
                                <strong>{data?.['edge-device']?.['atmos-data']?.['product-cookie']?.['Memory']}</strong>
                                <span>Memory</span>
                            </div>
                            <div className="stats-alpha">
                                <strong>{data?.['edge-device']?.['atmos-data']?.['product-cookie']?.['Memory Type']}</strong>
                                <span>Memory Type</span>
                            </div>
                            <div className="stats-alpha">
                                <strong>{data?.['edge-device']?.['atmos-data']?.['product-cookie']?.['Performance']}</strong>
                                <span>Performance</span>
                            </div>
                            <div className="stats-alpha">
                                <strong>{data?.['edge-device']?.['user_data']?.['aop'] + ' W'}</strong>
                                <div className="d-flex align-items-center">
                                    <span>OAP</span>
                                    <OverlayTrigger
                                        placement="right"
                                        overlay={
                                            <Popover id="popover-basic">
                                                <Popover.Body>
                                                    <span className='color-text text-center d-flex'>Orbit Average Power</span>
                                                </Popover.Body>
                                            </Popover>
                                        }
                                    >
                                        <sup>
                                            <i className="fe fe-info ms-1 fs-12 text-info"></i>
                                        </sup>
                                    </OverlayTrigger>
                                </div>
                            </div>
                            <div className="stats-alpha">
                                <strong>{data?.['edge-device']?.['atmos-data']?.['max_power_consumption_in_watts'] + ' W'}</strong>
                                <span>Peak Power</span>
                            </div>
                            <div className="stats-alpha">
                                <strong>{data?.['edge-device']?.['user_data']?.['time_per_orbit']} min</strong>
                                <span>Time Per Orbit</span>
                            </div>
                        </div>
                    </div>
                    <div className="common-card-content border-top">
                        <div className="d-flex">
                            <strong>{data?.['edge-ssd']?.['atmos-data']?.['name']}</strong>
                            {data?.['edge-ssd']?.['user_data']?.['numUnits'] && <div className="ms-1">({data?.['edge-ssd']?.['user_data']?.['numUnits']})</div>}
                        </div>
                        <div className="d-flex flex-wrap">
                            <div className="stats-alpha w-22">
                                <strong>{data?.['edge-ssd']?.['atmos-data']?.['product-cookie']?.['Capacity']}</strong>
                                <span>Capacity</span>
                            </div>
                            <div className="stats-alpha w-32">
                                <strong>{data?.['edge-ssd']?.['atmos-data']?.['max_power_consumption_in_watts'] + ' W'}</strong>
                                <span>Peak Power</span>
                            </div>
                            <div className="stats-alpha w-42 border-left ps-3">
                                <strong>{data?.['edge-ssd']?.['user_data']?.['aop'] + ' W'}</strong>
                                <span>Orbit Average Power (w)</span>

                            </div>
                        </div>
                    </div>
                </div>
            })}
            {satEdges?.length < 2 && <div className="common-card-box-empty d-flex justify-content-center"
                style={{ width: '440px' }}
                onClick={() => {
                    if (satEdges?.length < 2) {
                        navigateTo(`${mission_name}/${mission_id}/${sat_name}/${sat_id}/add_new_edge`, navigate)
                    } else {
                        toast.warning('You can only add up to 2 Edge devices', { toastId: 'o' })
                    }

                }}>

                <div className="d-flex flex-column text-center gap-3">
                    <i className="fe fe-plus fs-50 fw-light"></i>
                    <span className="fs-16">Add Edge Device</span>
                </div>

            </div>}
        </div>

        <div className='h-100p'>
            <div className="navigator-btn gap-3">
                <CloseButton />
                <div className="btn-group btn-grp-nav">
                    <Button
                        variant={hover ? "outline-primary" : "primary"}
                        type="button"
                        className={`d-flex align-items-center btn ${hover ? "btn-outline-primary" : "btn-primary"
                            } pt-1 pb-1 pe-4 ps-3 fs-14`}
                        onClick={() => {
                            navigateTo(`${mission_name}/${mission_id}/${sat_name}/${sat_id}/preview_payload`, navigate)
                        }}
                        onMouseEnter={() => {
                            setHover(false);
                        }}
                        onMouseLeave={() => {
                            setHover(true);
                        }}
                    >
                        <i className="fe fe-chevron-left me-1 fs-16"></i> Back
                    </Button>
                    <Button
                        variant={hover ? "primary" : "outline-primary"}
                        type="submit"
                        className={`d-flex align-items-center btn ${hover ? "btn-primary" : "btn-outline-primary"
                            } pt-1 pb-1 pe-3 ps-4 fs-14`}
                        onClick={() => {
                            if (isEdgeDelete) {
                                setIsEdgeDelete(false)
                            }
                            navigateTo(`${mission_name}/${mission_id}/${sat_name}/${sat_id}/add_new_template`, navigate)
                        }}
                    >
                        {isEdgeDelete ? 'Save & Continue' : 'Next'}
                        <i className="fe fe-chevron-right ms-1 fs-16"></i>
                    </Button>
                </div>
            </div>

            <div className="stepper-container">
                <div className="breadcrumb">
                    {Steps.map((data) => {
                        return <a
                            onClick={() => { navigate_to_step(data.title) }}
                            className={data.title === 'Edge' ? 'active' : ''}
                            key={data.title}>
                            <span className={"breadcrumb__inner"}>
                                <span className="breadcrumb__title">
                                    {data.svg}
                                </span>
                                <span className="breadcrumb__desc">{data.title}</span>
                            </span>
                        </a>
                    })}
                </div>
            </div>
        </div>

        <Dialog
            onClick={(event) => {
                if (event) {
                    deleteEdge(deletedEdgeData)
                }
                setOpenDeleteModal(false)
                setDeletedEdgeData({})
            }}
            openModel={openDeleteModel} comment='Are you sure you want to delete this edge device ?'
        />
    </div>;
};
export default EdgePreview;

