import React, { useEffect, useState } from "react";
import CommsConfig from "./CommsConfig";
import CommsCount from "./CommsCount";
import MissionService from "../../../../../service/MissionService";
import "./CommsTele.css"

function renderCommsPanel(commsPanels, type, validation, selectedCOMMSButton) {

  const panels = selectedCOMMSButton === "UHF" ?  commsPanels?.["UHF"]?.[type]?.sort((a, b) => a.id - b.id) : commsPanels?.["SBand"]?.['UPLink']?.sort((a, b) => a.id - b.id);

  let downlink = type==="DownLink"?true:false;

  const replaceValidationPanelURL = (url) => {
    if (validation && Object.keys(validation).length > 0) {
        let startTime = new Date(validation?.['startTime']).getTime()
        let endTime = (validation?.['endTime'] && (new Date(validation?.['startTime']).getTime() !== new Date(validation?.['endTime']).getTime())) ? new Date(validation?.['endTime']).getTime() : 'now' 
        
        return url.split('from=')[0] + 'from=' + startTime + '&to=' + endTime + '&var-re' + url.split('&var-re')[1]
    }else{
        return url
    }
  }

  return (
    <div className="col-12 row no-gutters ">
      {panels?.map((item, index) => (
        item?.id > 0 && (
          <div key={index} className={downlink && item?.id>2? "col-12 no-gutters" :"col-6 no-gutters"} >
            <label className="ms-5" style={{ color: "#CCF54E" }}>
              {item?.["panel_name"]}
            </label>
            <iframe className="col-12 h-300  p-0" src={`${process.env.PUBLIC_URL}${replaceValidationPanelURL(item?.["url"])}`} />
          </div>
        )
      ))}
    </div>
  );
  
}

function CommsMain({ selectedCOMMSSubButton, configStep, commsPanels ,scenarioId,trutwinId, validation, selectedCOMMSButton}) {
  const [commsCount,setCommsCount]=useState({})


 useEffect(() => {
    const fetchData = () => {
      MissionService.getCommsCount(trutwinId, scenarioId)
        .then((res) => {
          if (res?.data) {
            setCommsCount(res?.data);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    };

    // Initial fetch
    fetchData();

    // Fetch data every 3 seconds
    const intervalId = setInterval(fetchData, 3000);

    // Clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, [trutwinId, scenarioId]);


  return (
    <>
    <div className="row no-gutters">
      {selectedCOMMSButton === "UHF" && <div className="col-md-3 ">
       <CommsConfig selectedCOMMSSubButton={selectedCOMMSSubButton} configStep={configStep} commsCount={commsCount} />
      </div>}
      <div className={selectedCOMMSButton === "UHF" ? "col-md-9" : "col-md-12"}>
        {selectedCOMMSButton === "UHF" && <CommsCount selectedCOMMSSubButton={selectedCOMMSSubButton} configStep={configStep} commsCount={commsCount} panels={commsPanels} />}
        {selectedCOMMSButton === "UHF" && selectedCOMMSSubButton === "UPLINK" && renderCommsPanel(commsPanels, "UPLink", validation, selectedCOMMSButton)}
        {selectedCOMMSButton === "UHF" && selectedCOMMSSubButton === "DOWNLINK" && renderCommsPanel(commsPanels, "DownLink", validation, selectedCOMMSButton)}
        {selectedCOMMSButton === "S Band" && renderCommsPanel(commsPanels, "DownLink", validation, selectedCOMMSButton)}
      </div>
    </div>
  
  
  </>
  
  );
}

export default CommsMain;
